<template>
  <v-app style="background-color: #eceff1">
    <notifications group="gral_notif" position="bottom right" :width="400" />
    <div class="text-center">
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando aplicación
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { getRedireccionToken } from "./redireccion.service";

export default {
  name: "redirectionView",
  computed: {
    ...mapGetters(["masterApp"]),
  },
  async mounted() {
    this.token = this.$route.params.token;
    this.validarToken();
  },
  data: () => ({
    dialog: false,
    token: null,
  }),
  methods: {
    async validarToken() {
      this.dialog = true;
      try {
        const serverResponse = await getRedireccionToken(this.token);
        if(!serverResponse.ok) throw new Error(serverResponse.mensaje);
        localStorage.setItem("cgu-token", serverResponse.token);
        this.$router.push('/');
      } catch (error) {
        this.$notificarError(error);
        window.open(`${this.masterApp}/apps`, "_self");
      }
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>